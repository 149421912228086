<template>
  <Page :title="$t('entities-verification.title')" :classes="['page--entities-verification']">
    <EntitiesTable
      :filter="filter"
      :limit="10"
    ></EntitiesTable>
  </Page>
</template>

<script>
import EntitiesTable from '@/components/entities/EntitiesTable.vue';

export default {
  name: 'EntitiesVerificationPage',
  components: {
    EntitiesTable,
  },
  data() {
    return {
      filter: {
      },
    };
  },
};
</script>
