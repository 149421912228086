<template>
  <Table
    :rows="entities"
    :columns="columns"
    :onrowclick="onRowClick"
    :rowactions="rowactions"
    :isReady="isReady"
    :isLoading="isLoading"
    :pagination="entitiesPagination"
    :filters="filters"
    :filter="filter"
    v-model:offset="offset"
    v-model:limit="actualLimit"
    :classes="['table--entity']"
  ></Table>
</template>

<script>
import { mapGetters } from 'vuex';
import Generator from '@/services/generator/GeneratorService';
import ModelTableMixin from '@/components/layout/table/ModelTableMixin';
import UrlService from '@/services/url/UrlService';

const urlService = new UrlService();

export default {
  name: 'EntitiesTable',
  mixins: [
    ModelTableMixin,
  ],
  data() {
    return {
      model: 'entity',
      updateModel: true,
      columns: [{
        head: this.$t('common.entity'),
        component: 'CompanyChip',
        componentAttrs: (company) => ({ company, classes: ['chip--row'] }),
      }, {
        head: this.$t('common.vat_number'),
        class: 'pre',
        value: (item) => item.vat_number,
      }, {
        head: this.$t('common.address'),
        value: (item) => item.address,
      }, {
        head: this.$t('common.status'),
        class: 'status',
        component: 'Status',
        componentAttrs: (item) => ({ value: item.is_approved ? 'approved' : 'pending' }),
      }],
      filters: [{
        component: 'InputText',
        property: 'name',
        componentProps: {
          label: this.$t('common.name'),
        },
      }, {
        component: 'InputText',
        property: 'vat_number',
        componentProps: {
          label: this.$t('common.vat_number'),
        },
      }, {
        component: 'InputSelect',
        property: 'is_approved',
        componentProps: {
          label: this.$t('common.status'),
          options: Generator.trueFalse(this.$t('status.approved'), this.$t('status.pending')),
        },
      }],
      rowactions: [{
        label: this.$t('common.change_mark_revised'),
        class: (item) => (item.is_active ? 'approved' : 'pending'),
        function: this.changeApprovedStatus,
      }],
    };
  },
  computed: {
    ...mapGetters(['entities', 'entitiesPagination']),
  },
  methods: {
    changeApprovedStatus(item) {
      this.$store.dispatch('partial_update', {
        model: 'entity',
        id: item.id,
        data: {
          is_approved: !item.is_approved,
        },
      }).then((response) => {
        const idx = this.entities.findIndex((i) => i.id === response.data.id);
        this.entities[idx] = response.data;
      });
    },
    onRowClick(row) {
      const route = urlService.entityRoute(row, this.$i18n.locale);
      this.$router.push(route);
    },
  },
};
</script>
